
#filtreCubugu {
  padding: 5px;
  /*padding-left: 20px;*/
  left: 0 !important;
  width: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  position: absolute;
  color: white;
  border: 1px solid black;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  max-height: 80%;
}


#filtreDugme {
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #004E7C;
  color: #004E7C;
  padding: 5px;
}

#yanDugmeGrup {
  /*top: 0;*/
  left: 0;
  position: absolute;
  z-index: 2;
  overflow: hidden;
}


.ustSagKose {
  position: absolute;
  top: 0;
  right: 0;
}