// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//font awesome
$fa-font-path : "~@fortawesome/fontawesome-free/webfonts";

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

@import "node_modules/bootstrap-select/sass/bootstrap-select.scss";
@import "node_modules/select2/src/scss/core.scss";

@import "node_modules/bootstrap4-toggle/css/bootstrap4-toggle.min";

@import "node_modules/@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4.scss";

@import "node_modules/vue-toastification/dist/index";

@import '../KNET_resources/css/filtreCubugu';
@import '../KNET_resources/css/temel';
@import '../KNET_resources/css/menu';
@import '../KNET_resources/css/kamunetLogo';
@import '../KNET_resources/css/logs';
@import '../KNET_resources/css/paginator';

