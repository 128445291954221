html { height: 100%; }
body { background-image: url('/images/kktc_logo_white_surrounded_mosaic_greyscale_light.png');
background-position: center;
background-repeat: repeat;
height: 100%;}

/* floating terms */
.clear {clear: both;}
.clearRight {clear: right;}
.clearLeft {clear: left;}

.redBorder {
  border: 1px solid red;
}

.noPadding { 
  margin: 0; padding: 0px;}

.noOverflow { overflow: hidden; }

.doNotShow { display:none; }

.sonenBar {
	background: linear-gradient(to right, #004E7C  , white);
	color: white
}


.busyAnimation {
  animation: blinker 1s linear infinite;
  background-color: red !important;
  color: white !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.errorAnimation {
  background-color: red;
}

.uyariAnimation {
  width: 25px;
  height: 20px;
  background-image: url('/images/unlemSari.png');}

label.required:not(:empty):after{
  content: "*";
  color: red;
  vertical-align: super;
  font-size: 0.7em;
}

.dikeyMetin {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.caprazMetin {
  transform: rotate(-45deg);
}


.text-sm {
  font-size: 0.8rem;
}

.text-xs {
  font-size: 0.6rem;
}

legend {
  font-weight: bold;
}

tr.kaymayan-th-iceren-tr th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 1;
}