
.superKullanici {
  background-color: red;
  color: white;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}


/*
.menuLink a:hover {
  border-bottom: 2px solid red;
}
*/
a.menuLink {
  position: relative;
  color: white;
  text-decoration: none;
}

a.menuSecili {
  border-bottom: 1px dotted red;
}

a.menuLink::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: 1;
  bottom: 0;
  left: 0;
  background-color: red;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

a.menuLink:hover::before {
  visibility: visible;
  transform: scaleX(1);
  border: none !important;
}