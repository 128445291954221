  .kamunetLogo {
    font-family: Times New Roman;
    font-size: 0.9rem;
  }

  .sistemLogo {
    font-family: Times New Roman;
    font-size:1.1rem;
  }

  .buyukKamunetLogo {
  	font-family: 'Nunito', sans-serif;
  	font-size: 50px;
  	color: #636b6f;
  	font-weight: 300;
  }